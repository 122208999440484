<template>
  <div class="buy">
    <div class="container top_body">
      <!-- <div class="p_title">
        {{ $t(`marketMall.${market}Market.buyPage.p_title1`) }}
      </div> -->
      <div class="card_list flex">
        <!-- <img-div
          :img-url="
            choosedItem.product_id === item.product_id
              ? require(`@/assets/marketMall/${
                  cardType[item.type]
                }_card_sel.png`)
              : require(`@/assets/marketMall/${
                  cardType[item.type]
                }_card_nor.png`)
          "
          height="200"
          class="card_item"
          v-for="item in card_list"
          :key="item.product_id"
          @click.native="chooseCard(item)"
        >
          <div class="card_content flex flex-v">
            <span class="type_name">{{ item.name }}</span>
            <div class="type_price flex">
              <span class="parseInt">{{ item.price.split(".")[0] }}</span>
              <span class="flex flex-aEnd"
                >.{{ item.price.split(".")[1] || "00" }}</span
              >
            </div>
            <span class="type_long">{{ item.ccy }}/月</span>
          </div>
        </img-div> -->
        <div
          height="200"
          class="card_item flex flex-jsb"
          :class="{ active: choosedItem.product_id === item.product_id }"
          v-for="item in card_list"
          :key="item.product_id"
          @click="chooseCard(item)"
        >
          <div>
            <div class="card_content flex flex-v">
              <span class="fw-600 fs-24">{{ item.name }}</span>
              <div class="type_price flex">
                <span class="fw-600 fs-48">{{ item.price.split(".")[0] }}</span>
                <span class="flex flex-aEnd fs-14"
                  >.{{ item.price.split(".")[1] || "00" }}</span
                >
              </div>
              <span class="fs-14">{{ item.ccy }}/月</span>
            </div>
          </div>
          <div class="flex flex-aEnd right_img">
            <img
              :src="require(`@/assets/marketMall/${item.type}.png`)"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="radio radio_continu flex flex-vc fs-18">
        <!-- <img
          :src="
            renew
              ? require('@/assets/marketMall/radio_circle.png')
              : require('@/assets/inner-layout/radio_no.png')
          "
          alt=""
          @click="changeRenew"
        /> -->
        <svg-icon
          :name="renew ? 'check' : 'circle'"
          :size="18"
          :icon-class="renew ? 'check_icon checked' : 'check_icon'"
          @click.native="changeRenew"
        />
        {{ $t(`marketMall.${market}Market.buyPage.renewRadioText`) }}
      </div>
      <div class="p_title fs-18">
        {{ $t(`marketMall.${market}Market.buyPage.p_title2`) }}
      </div>

      <div class="selectAcc fs-14">
        <div
          v-for="item in userAccountList"
          :key="item.code"
          :value="item.code"
          class="selectAcc_item flex flex-jsb flex-vc"
          @click="selectAccFun(item)"
        >
          <span>{{ `${item.name}(${item.code})` }}</span>
          <svg-icon
            :size="18"
            name="right-line"
            icon-class="rightLine"
            v-show="userAccount === item.code"
          />
          <div
            class="popover_ fs-12"
            v-if="showPoper && userAccount === item.code"
          >
            <div
              v-if="cash_withdrawable < choosedItem.price"
              v-html="$t(`marketMall.${market}Market.buyPage.userAccTip`)"
            ></div>
            <template v-else-if="accountItem.status === 'C'">
              <div
                v-html="$t(`marketMall.${market}Market.buyPage.userAccTipC`)"
              ></div>
            </template>
          </div>
        </div>
        <!-- <el-select
          v-model="userAccount"
          placeholder="请选择"
          class="choseAcc"
          @change="selectAccount"
        >
          <el-option
            v-for="item in userAccountList"
            :key="item.code"
            :label="`${item.name}(${item.code})`"
            :value="item.code"
          >
          </el-option>
        </el-select>
        <div class="popover_" v-if="showPoper">
          <div
            v-if="cash_withdrawable < choosedItem.price"
            v-html="$t(`marketMall.${market}Market.buyPage.userAccTip`)"
          />
          <template v-else-if="accountItem.status === 'C'">
            <div
              v-html="$t(`marketMall.${market}Market.buyPage.userAccTipC`)"
            />
          </template>
        </div> -->
      </div>
    </div>
    <div class="tip_container">
      <div class="container">
        <div class="tip_title">
          {{ $t(`marketMall.${market}Market.buyPage.buyTipTitle`) }}
        </div>
        <div
          class="tip_content"
          v-html="$t(`marketMall.${market}Market.buyPage.buyTipContent`)"
        ></div>
      </div>
    </div>
    <div class="bottom_body">
      <div class="cancel_month flex flex-hc" v-if="choosedItem.renew == '1'">
        <el-button class="cancel_button" @click="cancelMonth">{{
          $t(`marketMall.${market}Market.buyPage.cancel_month`)
        }}</el-button>
      </div>
      <template v-else>
        <div class="container">
          <div class="radio buy_radio flex flex-vc">
            <!-- <img
              :src="
                readed
                  ? require('@/assets/marketMall/radio_circle.png')
                  : require('@/assets/inner-layout/radio_no.png')
              "
              alt=""
              @click="readed = !readed"
            /> -->
            <svg-icon
              :name="readed ? 'check' : 'circle'"
              :size="14"
              :icon-class="readed ? 'check_icon checked' : 'check_icon'"
              @click.native="readed = !readed"
            />
            <div class="buy_read">
              <span
                v-html="$t(`marketMall.${market}Market.buyPage.readed`)"
              ></span>
              <a
                href="javascript:;"
                @click="disclaimerVisible = !disclaimerVisible"
                v-html="$t(`marketMall.${market}Market.buyPage.readedHref`)"
              ></a>
            </div>
          </div>
        </div>
        <div class="buy_bottom fs-12">
          <div class="container flex flex-jsb flex-vc">
            <div class="flex flex-v">
              <span>{{
                $t(`marketMall.${market}Market.buyPage.buy_bottom_left`)
              }}</span>
              <div class="flex money_num">
                <span>{{ choosedItem.price }}</span>
                <span class="flex flex-aEnd">{{ choosedItem.ccy }}</span>
              </div>
            </div>
            <el-button
              type="primary"
              :disabled="!readed || showPoper"
              round
              class="buy_now"
              @click="submmit"
              >{{ $t(`marketMall.${market}Market.buy_now`) }}</el-button
            >
          </div>
        </div>
      </template>
    </div>
    <empty-dialog v-model="orderVisible" width="480px">
      <div class="order_content">
        <div class="dialog_title">
          <!-- {{ $t(`marketMall.${type}Market.question_title`) }} -->
          {{ $t(`marketMall.${market}Market.buyPage.orderTitle`) }}
        </div>
        <div class="order_body">
          <div class="order_item flex flex-vc flex-jsb">
            <span>{{
              $t(`marketMall.${market}Market.buyPage.full_name`)
            }}</span>
            <span>{{ choosedItem.full_name }}</span>
          </div>
          <div class="order_item flex flex-vc flex-jsb">
            <span>{{ $t(`marketMall.${market}Market.buyPage.ccyPrice`) }}</span>
            <span>{{ choosedItem.price + choosedItem.ccy }}</span>
          </div>
          <div class="order_item flex flex-vc flex-jsb">
            <span>{{ $t(`marketMall.${market}Market.buyPage.nameCode`) }}</span>
            <span>{{ `${accountItem.name}(${accountItem.code})` }}</span>
          </div>
          <div class="order_item flex flex-vc flex-jsb">
            <span>{{ $t(`marketMall.${market}Market.buyPage.end_date`) }}</span>
            <span>{{ checkedBody.end_date | formatDate("yyyy-MM-dd") }}</span>
          </div>
          <div class="order_tip">
            <span v-html="$t(`marketMall.${market}Market.buyPage.ftip`)"></span>
            <span
              v-show="renew"
              v-html="$t(`marketMall.${market}Market.buyPage.ftip1`)"
            >
            </span>
          </div>
        </div>
        <div class="order_footer flex flex-jend flex-vc">
          <el-button @click="orderVisible = !orderVisible">{{
            $t(`marketMall.${market}Market.cancelText`)
          }}</el-button>
          <el-button type="primary" @click="buyFun">{{
            $t(`marketMall.${market}Market.confirmText`)
          }}</el-button>
        </div>
      </div>
    </empty-dialog>
    <agreement-dialog
      v-model="disclaimerVisible"
      :confirm-text="$t(`common.defineText`)"
      :title="$t(`marketMall.${market}Market.buyPage.readedHref`)"
    >
      <disclaimer />
    </agreement-dialog>

    <PasswordInput
      ref="passwordInputRef"
      v-model="password"
      has-gap
      @complete="onPasswordInputComplete"
      :visible="passVisible"
      @onClose="handleClose"
      @onSuccess="tradePwdSuccess"
      @onError="tradePwdError"
    />
  </div>
</template>
<script>
import PasswordInput from "@/components/business/PasswordInput.vue";
import EmptyDialog from "@/components/business/EmptyDialog.vue";
import Disclaimer from "@/views/about/disclaimer";
import AgreementDialog from "@/components/business/AgreementDialog.vue";
import ImgDiv from "@/components/base/ImgDiv.vue";
import { mapGetters, mapMutations } from "vuex";
import api from "@/api";
export default {
  name: "MarketBuy",
  components: {
    AgreementDialog,
    PasswordInput,
    EmptyDialog,
    Disclaimer,
    ImgDiv,
  },
  computed: {
    ...mapGetters(["userInfo", "userAccountList"]),
    market() {
      return this.$route.query.market || "hk";
    },
    user_type() {
      return this.$route.query.user_type;
    },
    showPoper() {
      return (
        this.userAccount &&
        this.card_list.length > 0 &&
        this.withdrawableQuested &&
        (this.cash_withdrawable < this.choosedItem.price ||
          this.accountItem.status === "C")
      );
    },
  },
  data() {
    return {
      cardType: {
        1: "phone",
        2: "pc",
      },
      orderVisible: false,
      passVisible: false,
      disclaimerVisible: false,
      password: "",
      readed: false,
      userAccount: "",
      accountItem: {},
      card_list: [],
      choosedItem: {},
      cash_withdrawable: 0,
      withdrawableQuested: false,
      renew: false,
      checkedBody: {},
    };
  },
  watch: {
    passVisible(val) {
      if (val) this.$refs["passwordInputRef"].focus();
    },

    // userAccount(val) {
    //   console.log(val);
    // },
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    selectAccFun({ code }) {
      const { renew } = this.choosedItem;
      if (Number(renew) === 1) return;
      this.userAccount = code;
      this.selectAccount(code);
    },

    // 选择支付账户
    selectAccount(val) {
      const accountItem = this.userAccountList.filter(
        (item) => item.code === val
      );
      this.accountItem = accountItem[0] || {};
      this.getWithdrawable();
    },
    init() {
      const { user_region, ext = {} } = this.userInfo;
      // 获取行情类型卡
      api.marketMall
        .quoteProductListV3({
          os: "android",
          user_region,
          market: this.market,
          user_type: this.user_type || ext.user_type,
        })
        .then((res) => {
          this.card_list = res.list;
          if (res.list.length > 0) this.chooseCard(res.list[0]);
        });
      // 默认选中支付账户
      const code = this.userAccountList[0]?.code;
      if (code) {
        this.userAccount = code;
        this.selectAccount(code);
      }
    },
    changeRenew() {
      const { renew } = this.choosedItem;
      if (Number(renew) === 1) return;
      this.renew = !this.renew;
    },
    navigateTo(path) {
      this.SET_LOGIN_VISIBLE(false);
      this.$router.push(path);
    },
    ...mapMutations("user", ["SET_LOGIN_VISIBLE", "SET_USER_ACCOUNT_LIST"]),
    // 选择行情类型卡
    chooseCard(item) {
      if (this.choosedItem != item) {
        this.choosedItem = item;
        const { renew } = item;
        if (renew === 1) this.renew = true;
        else this.renew = false;
        this.getWithdrawable();
      }
      // this.withdrawableQuested = false;
    },
    getWithdrawable() {
      const { ccy } = this.choosedItem;
      if (!ccy) return;
      api.user
        .userCashWithdrawable({
          ccy: ccy,
          user_acc_code: this.userAccount,
        })
        .then((res) => {
          // console.log(res);
          this.cash_withdrawable = res.cash_withdrawable;
          this.withdrawableQuested = true;
        })
        .catch(() => {
          this.withdrawableQuested = true;
        });
    },
    // 提交
    async submmit() {
      const list = await api.user.userTradeAccountList();
      this.SET_USER_ACCOUNT_LIST(list);
      const { product_id } = this.choosedItem,
        { code } = this.accountItem,
        renew = this.renew ? "1" : "0";
      api.marketMall
        .quoteCardCheckV3({
          os: "android",
          channel: "web",
          renew,
          product_id,
          user_acc_code: code,
        })
        .then((res) => {
          if (res.new_card === 0) {
            this.$confirm(
              res.prompt,
              this.$t(
                `marketMall.${this.market}Market.buyPage.open_continue_title`
              ),
              {
                confirmButtonText: this.$t(
                  `marketMall.${this.market}Market.confirmText`
                ),
                cancelButtonText: this.$t(
                  `marketMall.${this.market}Market.cancelText`
                ),
                customClass: "confirm_empty",
                dangerouslyUseHTMLString: true,
                showClose: false,
              }
            ).then(() => {
              this.buyFun();
            });
          } else if (res.new_card === 1) {
            this.checkedBody = res;
            this.orderVisible = true;
          }
        })
        .catch((err) => {
          // console.log(err);
          if (!err.msg) return;
          this.$confirm(err.msg, "", {
            confirmButtonText: this.$t(
              `marketMall.${this.market}Market.confirmText`
            ),
            cancelButtonText: this.$t(
              `marketMall.${this.market}Market.cancelText`
            ),
            customClass: "confirm_empty single_button",
            dangerouslyUseHTMLString: true,
            showClose: false,
            showCancelButton: false,
          });
        });
    },
    buyFun() {
      this.orderVisible = false;
      // const { new_card, prompt } = this.checkedBody;
      // if (new_card === 1 && prompt) {
      //   this.$confirm(prompt, "", {
      //     confirmButtonText: this.$t(
      //       `marketMall.${this.type}Market.submmitText`
      //     ),
      //     cancelButtonText: this.$t(`marketMall.${this.type}Market.cancelText`),
      //     customClass: "confirm_empty",
      //     dangerouslyUseHTMLString: true,
      //     showClose: false,
      //   }).then(() => {
      //     this.passVisible = true;
      //   });
      // } else {
      this.passVisible = true;
      // }
    },
    //取消连续包月
    cancelMonth() {
      this.$confirm(
        this.$t(`marketMall.${this.market}Market.buyPage.cancel_tip`),
        "",
        {
          confirmButtonText: this.$t(
            `marketMall.${this.market}Market.confirmText`
          ),
          cancelButtonText: this.$t(
            `marketMall.${this.market}Market.cancelText`
          ),
          customClass: "confirm_empty",
          dangerouslyUseHTMLString: true,
          showClose: false,
        }
      ).then(() => {
        this.passVisible = true;
      });
    },
    // 开通
    tradePwdSuccess() {
      const { product_id, renew: cRenew } = this.choosedItem,
        { code: user_acc_code } = this.accountItem,
        renew = this.renew ? "1" : "0";
      if (cRenew != 1) {
        api.marketMall
          .quotePayV3({
            os: "android",
            product_id,
            renew,
            channel: "web",
            market: this.market,
            user_acc_code,
          })
          .then(() => {
            this.handleClose();
            this.init();
            this.$message({
              message: this.$t(`marketMall.${this.market}Market.buySuccess`),
              type: "success",
            });
          });
      } else {
        api.marketMall
          .quoteCancelRenewalV3({
            os: "android",
            product_id,
            user_acc_code,
          })
          .then(() => {
            this.init();
            this.handleClose();
            this.$confirm(
              this.$t(`marketMall.${this.market}Market.buyPage.cancel_tip2`),
              "",
              {
                confirmButtonText: this.$t(
                  `marketMall.${this.market}Market.confirmText`
                ),
                cancelButtonText: this.$t(
                  `marketMall.${this.market}Market.cancelText`
                ),
                customClass: "confirm_empty single_button",
                dangerouslyUseHTMLString: true,
                showClose: false,
                showCancelButton: false,
              }
            );
          });
      }
    },
    tradePwdError(_, err) {
      // console.log(err);
      const { data } = err;
      if (data.code === "-1003") return;
      this.$confirm(
        this.$t(`marketMall.${this.market}Market.buyPage.tradePasswordError`),
        "",
        {
          confirmButtonText: this.$t(
            `marketMall.${this.market}Market.repeatInput`
          ),
          cancelButtonText: this.$t(
            `marketMall.${this.market}Market.cancelText`
          ),
          customClass: "confirm_empty",
          dangerouslyUseHTMLString: true,
          showClose: false,
        }
      )
        .then(() => {
          this.$refs["passwordInputRef"].focus();
        })
        .catch(() => {
          this.handleClose();
        });
    },
    // 交易密码弹框隐藏
    handleClose() {
      this.passVisible = false;
    },
    onPasswordInputComplete(val) {
      console.log("密码输入完成: ", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.buy {
  color: var(--blackColor);
  padding-top: 40px;
  padding-bottom: 125px;
  background-color: var(--bgGray);
  .top_body {
    padding-bottom: 40px;
    // background: var(--whiteColor);
  }
  .p_title {
    padding-bottom: 12px;
  }
  .card_item {
    width: 320px;
    margin-right: 40px;
    padding: 33px;
    border-radius: 16px;
    box-shadow: 0px 12px 30px -8px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    border: 2px solid transparent;
    &.active {
      background-color: var(--lightBlue);
      box-shadow: 0px 12px 30px -8px rgba($color: #377cf0, $alpha: 0.16);
      border-color: var(--blue);
    }
    .card_content {
      .type_price {
        margin: 12px 0;
        .flex-aEnd {
          line-height: 34px;
        }
      }
    }
    .right_img img {
      width: 64px;
      height: 64px;
      margin-bottom: -13px;
    }
  }
  .radio {
    img {
      width: 14px;
      margin-right: 8px;
    }
  }
  .check_icon {
    color: var(--explainColor);
    margin-right: 8px;
  }
  .checked {
    color: var(--blue);
  }
  .radio_continu {
    margin: 40px 0;
    img {
      width: 18px;
    }
  }
  ::v-deep .choseAcc {
    &.el-select {
      min-width: 400px;
      .el-input__inner {
        border-color: #eef1f3;
        border-radius: 2px;
      }
      .el-input__suffix {
        right: 10px;
        .el-select__caret.el-input__icon.el-icon-arrow-up {
          font-weight: bold;
          color: var(--iconGray);
          font-size: 16px;
        }
      }
    }
  }
  .tip_container {
    padding: 0px 0 25px;
    background-color: #f7f8f9;
    .tip_title {
      font-size: 16px;
      margin-bottom: 6px;
    }
    .tip_content {
      font-size: 14px;
      color: var(--explainColor);
      text-align: justify;
      line-height: 28px;
    }
  }
  .bottom_body {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: var(--whiteColor);
    .cancel_month {
      background: #20272e;
      padding: 22px 0;
      .cancel_button {
        background: #20272e;
        border: 1px solid rgba($color: #ffffff, $alpha: 0.2);
        padding: 11px 139px;
        color: var(--whiteColor);
      }
    }
    .buy_radio {
      margin-top: 0;
      padding: 12px 0 12px;
      .buy_read {
        a {
          color: var(--blue);
        }
      }
    }
    .buy_bottom {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 16px 0;
      .money_num {
        span {
          line-height: 30px;
          &:first-child {
            font-size: 32px;
            line-height: 45px;
            color: #ff3b30;
          }
        }
      }
      .buy_now {
        padding: 10px 40px;
        font-size: 20px;
        line-height: 26px;
        border-radius: 46px;
      }
    }
  }
  .selectAcc {
    // display: inline-block;
    background-color: var(--whiteColor);
    border-radius: 8px;
    width: 380px;
    cursor: pointer;
    .selectAcc_item {
      position: relative;
      padding: 15px 16px;
    }
    .rightLine {
      color: var(--blue);
    }
  }
  .popover_ {
    color: #946500;
    background: #ffebc0;
    border-radius: 4px;
    padding: 3px 6px;
    position: absolute;
    white-space: nowrap;
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(-50%);
    &::after {
      border-color: transparent;
      border-top-color: #ffebc0;
      content: " ";
      border-width: 6px;
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      left: 50%;
      top: 100%;
      transform: translateX(-50%) translateY(calc(-1px));
    }
    // span {
    // color: var(--blue);
    // }
  }
  .order_content {
    background: var(--whiteColor);
    border-radius: 8px;
    padding: 0px 32px 21px;
    color: var(--blackColor);
    font-size: 14px;
    .dialog_title {
      padding: 16px 0;
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
      border-bottom: 1px solid #eef1f3;
    }
    .order_body {
      padding: 20px 0;
      .order_item {
        margin-bottom: 10px;
        span:first-child {
          color: var(--iconGray);
        }
      }
      .order_tip {
        padding-top: 7px;
        color: var(--fontYellow);
      }
    }
    .order_footer {
      .el-button {
        padding: 5px 18px;
        font-weight: normal;
        line-height: 20px;
      }
    }
  }
}
</style>
